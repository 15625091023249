var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-status"},[_c('div',{class:[
      'icon-style',
      { active: _vm.filteredEngineStatus.includes('VEHICLE_MOVING') },
    ],on:{"click":function($event){return _vm.onFilterEngineStatusClicked('VEHICLE_MOVING')}}},[_c('icon-font',{staticStyle:{"color":"#00c246"},attrs:{"type":"icon-play1"}}),_c('p',[_vm._v(_vm._s(_vm.pageType === 'video' ? _vm.movingByDevicelist.length : _vm.listMoving_ve.length))])],1),_c('div',{class:[
      'icon-style',
      { active: _vm.filteredEngineStatus.includes('ENGINE_IDLE') },
    ],on:{"click":function($event){return _vm.onFilterEngineStatusClicked('ENGINE_IDLE')}}},[_c('icon-font',{staticStyle:{"color":"#faad14"},attrs:{"type":"icon-pause"}}),_c('p',[_vm._v(_vm._s(_vm.pageType === 'video' ? _vm.idleByDeviceList.length : _vm.listIdle_ve.length))])],1),_c('div',{class:[
      'icon-style',
      { active: _vm.filteredEngineStatus.includes('ENGINE_STOP') },
    ],on:{"click":function($event){return _vm.onFilterEngineStatusClicked('ENGINE_STOP')}}},[_c('icon-font',{staticStyle:{"color":"#f5223e"},attrs:{"type":"icon-stop"}}),_c('p',[_vm._v(_vm._s(_vm.pageType === 'video' ? _vm.stopByDeviceList.length : _vm.listStop_ve.length))])],1),_c('div',{class:[
      'icon-style',
      { active: _vm.filteredEngineStatus.includes('NOT_UPDATED') },
    ],on:{"click":function($event){return _vm.onFilterEngineStatusClicked('NOT_UPDATED')}}},[_c('icon-font',{staticStyle:{"color":"#aaaaaa"},attrs:{"type":"icon-cancel"}}),(_vm.pageType === 'video')?_c('p',[_vm._v(" "+_vm._s(_vm.filterVehicleByDevice().length - (_vm.movingByDevicelist.length + _vm.idleByDeviceList.length + _vm.stopByDeviceList.length))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.vehicles.length - (_vm.listMoving_ve.length + _vm.listIdle_ve.length + _vm.listStop_ve.length))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }